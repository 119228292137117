@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {
  .sidebar-link {
    @apply flex items-center gap-3 px-4 py-3 rounded-lg transition-all duration-200 ease-in-out;
  }
  
  .sidebar-link.active {
    @apply bg-blue-50 text-blue-600;
  }
  
  .sidebar-link:not(.active) {
    @apply text-gray-600 hover:bg-gray-50;
  }
  
  .stat-card {
    @apply transform transition-all duration-200 hover:scale-105 hover:shadow-lg;
  }
  
  .form-input {
    @apply transition-all duration-200 focus:ring-2 focus:ring-blue-500;
  }
}

.MuiDataGrid-root {
  @apply animate-fade-in;
}

.notification-item {
  @apply animate-slide-in;
}

.stat-number {
  @apply animate-scale;
}


@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
 
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
 
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
 
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
 
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
 
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
 
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;
 
    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
 
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
 
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
 
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;
 
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
 
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
 
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
 
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
 
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }

  html {
    @apply text-base font-sans;
    font-family: "Public Sans", sans-serif;
    font-size: 18px;
  }
  
}
